import { constant, branch } from '@/helpers/FP'
import promoData from '../../statics/promo.json'
import { isFeatureEnabled } from '../../helpers/isFeatureEnabled'

const skuSet = new Set(promoData.skus)
const promoText = constant(promoData.text)
const promoCode = constant(promoData.code)
const startTime = constant(new Date(promoData.start_date).getTime())
const endTime = constant(new Date(promoData.end_date).getTime())
const promoLocallyEnabled = constant(isFeatureEnabled('promoEnabled'))

const isCurrentTimeInPromoDateRange = () => {
  const currentTime = new Date().getTime()
  return currentTime >= startTime() && currentTime <= endTime()
}
const isSkuInPromo = (sku: string) => (sku ? skuSet.has(sku) : false)
const isPromoValid = branch(isSkuInPromo)(isCurrentTimeInPromoDateRange)(constant(false))

export {
  promoText,
  promoCode,
  isPromoValid,
  promoLocallyEnabled,
}
